<template>
    <div>
		<block v-if="loading"></block>
        <v-card flat class="pa-4 bar">
            <div class="d-flex justify-end align-center">
				<v-btn class="save" @click="downloadFullExcel" dark small>
					<v-icon class="mr-2">mdi-microsoft-excel</v-icon>
					Descargar Excel Completo
				</v-btn>
				<v-btn 
					small 
					class="btn-add ml-2"
					@click="printPreview"
				>
					<v-icon>
						mdi-printer
					</v-icon>
				</v-btn>
            </div>
        </v-card> 

        <v-row class="mx-1 mt-5">
            <v-col cols="12" md="5">
                <v-select
                    v-model="search.month"
                    :items="monthList"
                    item-text="text"
                    item-value="value"
                    label="Mes"
                    outlined
                    attach
                    dense
                ></v-select>
            </v-col>
            <v-col cols="12" md="5">
                <v-text-field
                    v-model="search.year"
                    label="Año"
                    outlined
                    type="number"
                    attach
                    dense
                    clearable
                ></v-text-field>
            </v-col>
			<v-col cols="12" md="2">
				<v-btn 
					small 
					class="btnsave"
					@click="getVerificationBalanceData"
				>
					Buscar Movimientos
				</v-btn>
			</v-col>
        </v-row>

		<v-row class="mx-1 mt-2">
			<form style="display: flex; flex-direction: row; align-items: center; gap: 40px;">
				<div class="book-radio">
					<label for="check-balance-category">
						<input
							type="radio" name="check-balance-category" id="check-balance-category"
							v-model="SheetCheckBalanceType" value="category"
							class="custom-radio"
						/>
						<span class="custom-radio"></span>
						Por rubro
					</label>
				</div>
				<div class="book-radio">
					<label for="check-balance-major-accounts">
						<input
							type="radio" name="check-balance-major-accounts" id="check-balance-major-accounts"
							v-model="SheetCheckBalanceType" value="major-accounts"
							class="custom-radio"
						/>
						<span class="custom-radio"></span>
						Por cuentas de mayor
					</label>
				</div>
				<div class="book-radio">
					<label for="check-balance-subaccounts">
						<input
							type="radio" name="check-balance-subaccounts" id="check-balance-subaccounts"
							v-model="SheetCheckBalanceType" value="subaccounts"
							class="custom-radio"
						/>
						<span class="custom-radio"></span>
						Por Subcuentas
					</label>
				</div>
			</form>
		</v-row>

		<v-row>
			<p style="margin: 20px 0 -4px 16px; font-weight: 600;">ACTIVOS</p>
			<DxDataGrid 
				:dataSource="DataTable.activos"
				:showBorders="true"
				:scrolling="{ useNative: true }"    
				:column-auto-width="true"
				@exporting="(e) => onExporting( 'ACTIVOS', e )"
			>
				<DxColumn dataField="account_code" caption="Cuenta" />
				<DxColumn dataField="account_description" caption="Nombre de Pasivo" />
				<DxColumn dataField="PreviousBalanceFormatted" caption="SalAnterior" />
				<DxColumn dataField="chargesFormatted" caption="Cargos" />
				<DxColumn dataField="paymentsFormatted" caption="Abonos" />
				<DxColumn dataField="CurrentBalanceFormatted" caption="SalActual" />
				<DxPager :showPageSizeSelector="true" :allowedPageSizes="[5, 10, 20]" />
				<DxPaging :defaultPageSize="10" />
				<DxExport :enabled="true" :formats="['xlsx']" />
			</DxDataGrid>
		</v-row>
		<v-row>
			<v-col md="8">
	
			</v-col>
			<v-col md="4">
				<p style="float:left; margin-right: 140px; text-align: right;">TOTAL ACTIVOS:</p><p style="overflow: hidden; text-align: right;"> {{ this.getTotal( "activos" ) }}</p>
			</v-col>
		</v-row>
		<v-row>
			<p style="margin: 20px 0 -4px 16px; font-weight: 600;">PASIVOS</p>
			<DxDataGrid 
				:dataSource="DataTable.pasivos"
				:showBorders="true"
				:scrolling="{ useNative: true }"    
				:column-auto-width="true"
				@exporting="(e) => onExporting( 'PASIVOS+CAPITAL', e )"
			>
				<DxColumn dataField="account_code" caption="Cuenta" />
				<DxColumn dataField="account_description" caption="Nombre de Pasivo" />
				<DxColumn dataField="PreviousBalanceFormatted" caption="SalAnterior" />
				<DxColumn dataField="chargesFormatted" caption="Cargos" />
				<DxColumn dataField="paymentsFormatted" caption="Abonos" />
				<DxColumn dataField="CurrentBalanceFormatted" caption="SalActual" />
				<DxPager :showPageSizeSelector="true" :allowedPageSizes="[5, 10, 20]" />
				<DxPaging :defaultPageSize="10" />
				<DxExport :enabled="true" :formats="['xlsx']" />
			</DxDataGrid>
		</v-row>
		<v-row>
			<v-col md="8">
	
			</v-col>
			<v-col md="4">
				<p style="float:left; margin-right: 140px; text-align: right;">TOTAL PASIVO+CAPITAL:</p><p style="overflow: hidden; text-align: right;"> {{ this.getTotal( "pasivos" ) }}</p>
			</v-col>
		</v-row>
        <alerts
			v-if="alert.show"
			v-on:close_alert="closeAlert"
			v-on:accept_alert="acceptAlert"
			:properties="alert"
		></alerts>
		<PrintCheckBalance :dataProps="print" :balanceDate="balanceDate"></PrintCheckBalance>
    </div>
</template>

<script>
import {DxDataGrid, DxPager, DxPaging, DxColumn, DxExport} from 'devextreme-vue/data-grid';
import PrintCheckBalance from '../../../components/ReportsAndPrintings/PrintCheckBalance.vue';
import alerts from '@/components/Alerts.vue';
import moment from 'moment';
import { numberWithCommas } from "../../../helpers/money";
import { printToExcel } from "@/helpers/printToexcel";
import xlsx from 'json-as-xlsx';

export default {
	name: "SheetCheckBalance",
	components: {
		DxExport,
		DxDataGrid,
		DxPager,
		DxPaging,
		DxColumn,
		PrintCheckBalance,
		alerts
	},
	props: ['win'],
	data() {
		return {
			DataTable: {
				activos: [],
				pasivos: []
			},
			SheetCheckBalanceType: 'category',
			panel: 0,
			tab: null,
			key: 0,
			search:{
				month: '',
				year: null,
			},
			typesDitionary: {
				category: 'por Rubro',
				subaccounts: 'por Subcuentas',
				"major-accounts": 'por Cuentas de Mayor'
			},
			alert: {
				type: "success",
				show: false,
				header: "",
				body: "",
			},
			loading: false,
			monthList: [
				{text: 'Enero', value: 1},
				{text: 'Febrero', value: 2},
				{text: 'Marzo', value: 3},
				{text: 'Abril', value: 4},
				{text: 'Mayo', value: 5},
				{text: 'Junio', value: 6},
				{text: 'Julio', value: 7},
				{text: 'Agosto', value: 8},
				{text: 'Septiembre', value: 9},
				{text: 'Octubre', value: 10},
				{text: 'Noviembre', value: 11},
				{text: 'Diciembre', value: 12},
			],
			print: {
				show: false,
				data: []
			},
			currencySymbol: JSON.parse(localStorage.getItem('branch')).Currency,
			businessName: JSON.parse(localStorage.getItem("user")).business,
			userData: {}
		}
	},
	watch: {
		SheetCheckBalanceType() {
			this.loading = true;
			this.cleanData();
			this.loading = false;
		},
	},
	mounted() {
		this.userData = JSON.parse(localStorage.getItem("user"))
	},
	computed:{
		balanceDateComplete() {
			const currentDate = moment();
	
			currentDate.set( 'month', this.search.month - 1 );
			currentDate.set( 'year', this.search.year );

			return currentDate;
		},
		balanceDate(){
			let currentMonth = this.balanceDateComplete.startOf("month").format('MMMM');

			currentMonth = `${ currentMonth.substring( 0, 1 ).toUpperCase() }${ currentMonth.substring( 1, currentMonth.length ) }`;

			let balanceDate = `${ currentMonth } del ${ this.balanceDateComplete.year() }`;
			return balanceDate;
		},
		shortDate() {
			const cases = [
				{search: 'Enero', replace: 'Ene'},
				{search: 'Febrero', replace: 'Feb'},
				{search: 'Marzo', replace: 'Mar'},
				{search: 'Abril', replace: 'Abr'},
				{search: 'Mayo', replace: 'May'},
				{search: 'Junio', replace: 'Jun'},
				{search: 'Julio', replace: 'Jul'},
				{search: 'Agosto', replace: 'Ago'},
				{search: 'Septiembre', replace: 'Sep'},
				{search: 'Octubre', replace: 'Oct'},
				{search: 'Noviembre', replace: 'Nov'},
				{search: 'Diciembre', replace: 'Dic'},
			]
			let date = this.balanceDate.replace('del ', '')
			for (const case_ of cases) {
				date = date.replace(case_.search, case_.replace)
			}	
			return date
		},
		columnWidth() {
			return this.$vuetify.breakpoint.smAndDown ? '150px' : 'auto !important';
		},
		fullData() {
			const activos = this.DataTable.activos.map(({
				account_code,
				account_description,
				PreviousBalanceFormatted,
				chargesFormatted,
				paymentsFormatted,
				CurrentBalanceFormatted
			}) => {
				return {
					account_code,
					account_description,
					PreviousBalanceFormatted,
					chargesFormatted,
					paymentsFormatted,
					CurrentBalanceFormatted
				}
			})
			const pasivosCapital = this.DataTable.pasivos.map(({
				account_code,
				account_description,
				PreviousBalanceFormatted,
				chargesFormatted,
				paymentsFormatted,
				CurrentBalanceFormatted
			}) => {
				return {
					account_code,
					account_description,
					PreviousBalanceFormatted,
					chargesFormatted,
					paymentsFormatted,
					CurrentBalanceFormatted
				}
			})

			return [
				{account_description: 'ACTIVOS'},
				...activos,
				{account_description: ''},
				{account_description: 'PASIVOS + CAPITAL'},
				...pasivosCapital
			]
		},
	},
	methods: {
		onExporting( label, e) {
			//let array = null;
			//array = ["saldo_anterior"]
			printToExcel(e, `Balance De Comprobación ${ this.balanceDate } (${ label })`);
		},
		getTotal( recordType ) {
            let accum = 0.00;

            for( const record of this.DataTable[ recordType ] ) {
				if( record.Type === 1 ) {
					accum += record.CurrentBalance ? record.CurrentBalance : 0;
				}
			}

            return `${ this.currencySymbol }${ numberWithCommas( accum ) }`;
        },
		cleanData() {
			this.DataTable = { 
				activos: [],
				pasivos: []
			}
		},
		closeAlert() {
			this.alert.show = false;
		},
		acceptAlert() {
			this.alert.show = false;

			if (this.alert.options == "deletecustomer") {
				this.deleteCustomer();
			}
		},
		showAlert(type, header, body, options = null) {
			type = type == null ? "danger" : type;
			this.alert.type = type;
			this.alert.header = header;
			this.alert.body = body;
			this.alert.show = true;
			this.alert.options = options != null ? options : null;
		},
		async getVerificationBalanceData(){
			this.loading = true;
			try {
				if(this.search.month == null || this.search.year == null){
					this.showAlert("danger", "Error", "Debe seleccionar un mes y un año");
					return;
				}
				if(this.search.year.length != 4){
					this.showAlert("danger", "Error", "El año debe tener 4 digitos");
					return;
				}
				const response = await this.$API.accountingreports.getVerificationBalance( { ...this.search, type: this.SheetCheckBalanceType } );
				this.validateLengthOfReport( response, "El periodo solicitado no posee registros contables", () => {
					this.DataTable = response;
				} );
			} catch (error) {
				this.showAlert(
					"warning",
					"No fue posible consultar los datos",
					`Vuelva a intentrlo y si el problema persiste comuniquese con su administrador`
				);
			} finally {
				this.loading = false;
			}
		},
		validateLengthOfReport( data, message, next ) {
			const { activos, pasivos } = data;

			if( activos.length === 0 && pasivos.length === 0 ) {
				this.showAlert(
					"warning",
					"No se encontraron datos",
					message
				);
			} else {
				next();
			}
		},
		printPreview(userData) {
			moment.locale('es_SV');

			const { activos, pasivos } = this.DataTable;

			this.print = {
				show: true,
				data: {
					activos,
					pasivos,	
					userData: userData,
				},
				balanceDate: this.balanceDate,
				lastDayOfDate: this.balanceDateComplete.endOf('month').format( 'DD' ),
				balanceType: this.typesDitionary[ this.SheetCheckBalanceType ],
				SheetCheckBalanceType: this.SheetCheckBalanceType
			}
		},
		downloadFullExcel() {
			if (this.fullData.length <= 3) {
				this.showAlert(
					"warning",
					"No se encontraron datos",
					"No ha consultado datos que pueda exportar"
				);
				return
			}
			const title = `Balance de comprob ${this.shortDate}`
			const fileName = `Balance de comprobacion ${this.balanceDate}`
			const format = "$0,0.00"

			const columns = [
				{ label: "Cuenta", value: "account_code" },
				{ label: "Descripción de la Cuenta", value: "account_description" },
				{ label: "SalAnterior", value: "PreviousBalanceFormatted", format },
				{ label: "Cargos", value: "chargesFormatted", format },
				{ label: "Abonos", value: "paymentsFormatted", format },
				{ label: "SalActual", value: "CurrentBalanceFormatted", format },
			]			

			const data = [{
				sheet: title,
				columns,
				content: this.fullData
			}]
			const settings = {
				fileName,
				extraLength: 3,
				writeMode: "writeFile",
				writeOptions: {},
				RTL: false
			}
			xlsx(data, settings)
		}
	},
}
</script>

<style scoped>
.book-radio {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	font-size: 16px;
	color: black;
	margin: 0 0 20px 12px;
}
.book-radio label {
	cursor: pointer;
}
.book-radio input[type="radio"] {
		display: none;
}
.book-radio .custom-radio {
	width: 20px;
	height: 20px;
	border: 2px solid #777;
	border-radius: 50%;
	display: inline-block;
	position: relative;
	cursor: pointer;
	transition: background-color 0.3s;
	align-self: center;
}
.book-radio .custom-radio::before {
	content: '';
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: transparent;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: background-color 0.3s;
}
.book-radio input[type="radio"]:checked + .custom-radio::before {
	background-color: #3498DB;
}
.book-radio label {
	display: flex;
	gap: 8px;
	align-items: center;
}
.bar {
max-width: 100%;
background-color: rgb(60, 60, 60) !important;
color: white !important;
border-style: solid;
border-color: black;
border-radius: 15px !important;
margin: 16px 12px !important;
}
.btn-add{
background-color: #3498DB  !important;
color: white;
}
.btnsave{
background-color: #00A178 !important;
color: white;
}
.btnclean{
background-color: #F29D35 !important;
color: white;
}
.btndelete{
background-color: #E3191E !important;
color: white;
}
</style>
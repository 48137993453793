<template>
    <window-portal :open="dataProps.show" @close="dataProps.show = false">
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center><div><p style="font-size: 30px;">Vista previa de impresión de Anexo de Balance de Comprobación</p></div></center>
            <br/>
            <center>
                <button class="btnclean" style="font-size: 18px;" @click="Print()">
                    Imprimir
                </button>
                &nbsp;&nbsp;
                <button class="btnclean" style="font-size: 18px;" @click="Download()">
                    Descargar
                </button>
                
            </center>
            <center><div v-if="loading"><p>Cargando...</p></div></center>

        </div>
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center v-if="mailLabel">
                {{ mailLabel }}
            </center>
        </div>

        <div class="book" style="margin: 0; padding: 0; background-color: rgb(105, 105, 105); font-family: arial !important; padding-top: 20px; padding-bottom: 20px;">
                <div style="margin: 0 auto; width: 1000px;">
                    <div ref="dataprint" style="margin: 0 !important; padding: 0 !important;">
                        <!--div style="position: relative; width: 1000px; min-height: 1294px; background: white;"-->
                        <div v-for="( { activos, pasivosCapital } , indexPage) in pages" :key="indexPage" style="position: relative; width: 1000px; min-height: 1250px; background: white;">
                            <hr/>
                            <div class="subpage" style="height: 1250px; padding-top: 20px; overflow: hidden;">
                                <div style="margin-left: 80px; margin-right: 50px;">
                                    <div style="text-align: center; font-size: 12px;">
                                        <h2>{{ businessName }}</h2>
                                        <p>{{ dataProps.balanceDate }}.</p>
                                        <p>{{ getcurrency }}</p>
                                    </div>
                                    <!-- <div>
							
                                        <p>Balance General a la Fecha de {{ dataProps.balanceDate }} {{ dataProps.balanceType }}</p>
                                    </div> -->
                                    <br/>
                                    <!-- Heres is teh body -->
                                    <div style="width: 100%;">
                                        <table v-if="activos.values.length > 0" style="width: 100% !important; margin-bottom: 22px; font-size: 12px; border-collapse: collapse;">
                                            <thead style="text-align: center; width: 100%;">
                                                <tr>
                                                    <th></th>
                                                    <th style="text-align: left;">
                                                        ACTIVOS
                                                    </th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, indexActivo) in activos.values" :key="indexActivo">
                                                    <td :style="`padding: 2px; width: 10%;`">{{ item["account_code"] }}</td>
                                                    <td :style="`padding: 2px; width: 30%;`">{{ item["account_description"] }}</td>
                                                    <td style="padding: 2px; width: 20%; text-align: right;">{{ item["ChildAccountsBalance"] ? numberWithCommas( item["ChildAccountsBalance"] ) : '' }}</td>
                                                    <td style="padding: 2px; width: 20%; text-align: right;">{{ item["SubAccountsBalance"] ? numberWithCommas(item["SubAccountsBalance"]) : '' }}</td>
                                                    <td style="padding: 2px; width: 20%; text-align: right;">{{ item["MajorAccountsBalance"] ? numberWithCommas(item["MajorAccountsBalance"]) : '' }}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr v-if="activos?.isLastPage">
                                                    <td colspan="4" style="text-align: right; padding-right: 30px;">TOTAL ACTIVO</td>
                                                    <td style="padding: 2px; width: 20%; text-align: right; border-top: double; border-bottom: double;">{{ getTotal( 'activos' ) }}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                        <table v-if="pasivosCapital.values.length > 0" style="margin-bottom: 22px; width: 100% !important; font-size: 12px; border-collapse: collapse;">
                                            <thead style="text-align: center; width: 100%;">
                                                <tr>
                                                    <th></th>
                                                    <th style="text-align: left;">
                                                        PASIVOS+CAPITAL
                                                    </th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, indexPasivo) in pasivosCapital.values" :key="indexPasivo">
                                                    <td :style="`padding: 2px; width: 10%;`">{{ item["account_code"] }}</td>
                                                    <td :style="`padding: 2px; width: 30%;`">{{ item["account_description"] }}</td>
                                                    <td style="padding: 2px; width: 20%; text-align: right;">{{ item["ChildAccountsBalance"] ? numberWithCommas(item["ChildAccountsBalance"]) : '' }}</td>
                                                    <td style="padding: 2px; width: 20%; text-align: right;">{{ item["SubAccountsBalance"] ? numberWithCommas(item["SubAccountsBalance"]) : '' }}</td>
                                                    <td style="padding: 2px; width: 20%; text-align: right;">{{ item["MajorAccountsBalance"] ? numberWithCommas(item["MajorAccountsBalance"]) : '' }}</td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr v-if="pasivosCapital?.isLastPage">
                                                    <td colspan="4" style="text-align: right; padding-right: 30px;">TOTAL PASIVO+CAPITAL</td>
                                                    <td style="padding: 2px; width: 20%; text-align: right; border-top: double; border-bottom: double;">{{ getTotal( 'pasivosCapital' ) }}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                    <div v-if="indexPage === pages.length - 1" style="width: 100%; margin-top: 150px;">
                                        <table style="width: 100%">
                                            <tr style="width: 100%">
                                                <td style="width: 33.33%" >
                                                    <div style="border-bottom: 0.5px solid; width: 80%; margin: auto;">
                                                        
                                                    </div>
                                                </td>
                                                <td style="width: 33.33%" >
                                                    <div style="border-bottom: 0.5px solid; width: 80%; margin: auto;">
                                                        
                                                    </div>
                                                </td>
                                                <td style="width: 33.33%" >
                                                    <div style="border-bottom: 0.5px solid; width: 80%; margin: auto;">
                                                        
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr style="width: 100%;">
                                                <td style="width: 33.33%" >
                                                    <div style="width: 80%; margin: auto; text-align: center; font-size: 12.5px;">
                                                        Representante Legal
                                                    </div>
                                                </td>
                                                <td style="width: 33.33%" >
                                                    <div style="width: 80%; margin: auto; text-align: center; font-size: 12.5px">
                                                        Auditor
                                                    </div>
                                                </td>
                                                <td style="width: 33.33%" >
                                                    <div style="width: 80%; margin: auto; text-align: center; font-size: 12.5px;">
                                                        Contador
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                
                                <div style="position: absolute; bottom: 0; width: 100%; left: 80px">
                                    <p style="font-size:10px;">Pagina {{ indexPage+1 }} de {{ pages.length }}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
        </div>
    </window-portal>
</template>

<script>

import jsPDF from "jspdf"
//import QRCode from 'qrcode';
//import html2canvas from "html2canvas"
import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue"
import { addFormatToNIT, addFormatNRC } from "../../helpers/misc"
import { numberWithCommas } from "../../helpers/money";
import {numberToText} from '@/helpers/conveterNumberText.js'

export default {
    name: "PrintCheckBalanceAnnex",
    props: ['dataProps' ],
    components: {WindowPortal},
    data() {
        return {
            header: null,
            footer: null,
            loading: false,
            //seal: "",
            //sealBranch: "",
            pages: [],
            //valuesRows: []
            mailLabel: null,
            valuesRows: [],
            urlQr: null,
            typePrint: 0,
            consolidateValues: {},
            borderTp: 0,
            rowsNumberPerReport: 47,
            businessName: JSON.parse( localStorage.getItem( 'user' ) ).business,
            currencySymbol: JSON.parse( localStorage.getItem( 'branch' ) ).Currency,
        }
    },
    mounted() {
        this.calculatePagesOfDoc()
        this.getHeaderFooter()
    },
    computed: {
        getBalanceIntegerType() {
            return {
                'por Rubro': 1,
                'por Cuentas de Mayor': 2,
                'por Subcuentas': 3
            }
        },
        getBigColumnWidth(){
            const balanceType = this.getBalanceIntegerType[ this.dataProps.balanceType ];

            const widthDictionary = [
                80,
                60,
                40
            ]
            
            return widthDictionary[ balanceType - 1 ];
        },
        getTotalColspan() {
            const balanceType = this.getBalanceIntegerType[ this.dataProps.balanceType ];

            const colspanDictionary = [
                1,
                2,
                3
            ]

            return colspanDictionary[ balanceType - 1 ];
        },
        getcurrency() {
            const countries = this.$store.getters.getCountries;
            if (!Array.isArray(countries)) return '';
            const currency = countries.find( current => current.currency_symbol === this.currencySymbol );

            return currency.currency_name.toUpperCase();
        },
        BillingNrc() {
            return addFormatNRC(this.dataProps.data.branch.BusinessBillingNRC)
        },
        BillingNit() {
            //console.log(this.dataProps.data.branch.BusinessBillingNIT);
            return addFormatToNIT(this.dataProps.data.branch.BusinessBillingNIT)
        }
    },
    watch: {
        'dataProps.show'() {
            if(this.dataProps.show){
                setTimeout(async () => {
                    await this.calculatePagesOfDoc()
                }, 0);
            }
        },
        'dataProps'() {
            this.calculatePagesOfDoc()
        }
    },
    methods: {
        getTotal( recordType ) {
            let accum = 0.00;

            for( const page of this.pages ) {
                const { values } = page[ recordType ];

                for( const record of values ) {
                    accum += record.MajorAccountsBalance ? record.MajorAccountsBalance : 0;
                }
            }

            return `${ this.currencySymbol }${ numberWithCommas( accum ) }`;
        },
        GetnumberToText(number) {
            if(number) return numberToText(number)
            else return ''
        },
        numberWithCommas(x) {
            return numberWithCommas(x);
        },
        getHeaderFooter() {
            let search = {};
            search.ID_business = JSON.parse(localStorage.getItem("user")).businessid || ""
            search.ID_branch = JSON.parse(localStorage.getItem("user")).branch || ""
            this.$API.HF.getHF(search).then((data) => {
                let header = [], footer = [];
                header = data.filter((x) => x.State == '1' && x.Type == 'Header');
                this.header = header.length > 0 ? JSON.parse(header[0].Body).html : '';
                this.header = this.header.replace(/(\n)/gm, "");
                footer = data.filter((x) => x.State == '1' && x.Type == 'Footer');
                this.footer = footer.length > 0 ? JSON.parse(footer[0].Body).html : '';
            })
        },
        calculatePagesOfDoc() {
            try{
                this.pages = [];
                const { data: { activos, pasivosCapital } } = this.dataProps;
            
                this.typePrint = 0;
                let currentPage = 0;
                let currentRowsPerPage = 0;

                this.pages[ currentPage ] = { activos: { values: [] }, pasivosCapital: { values: [] } };

                for( const i in activos ) {
                    const element = activos[ i ];

                    if( currentRowsPerPage <= this.rowsNumberPerReport ) {
                        currentRowsPerPage ++;
                        if( i == activos.length - 1 ) {
                            this.pages[ currentPage ].activos.values.push( element );
                            this.pages[ currentPage ].activos.isLastPage = true;
                        } else {
                            this.pages[ currentPage ].activos.values.push( element );
                        }
                    } else {
                        currentPage ++;
                        currentRowsPerPage = 0;
                        this.pages[ currentPage ] = { activos: { values: [ element ] }, pasivosCapital: { values: [] } };
                    }
                }

                for( const i in pasivosCapital ) {
                    const element = pasivosCapital[ i ];

                    if( currentRowsPerPage <= this.rowsNumberPerReport ) {
                        currentRowsPerPage ++;
                        if( i == pasivosCapital.length - 1 ) {
                            this.pages[ currentPage ].pasivosCapital.values.push( element );
                            this.pages[ currentPage ].pasivosCapital.isLastPage = true;
                        } else {
                            this.pages[ currentPage ].pasivosCapital.values.push( element );
                        }
                    } else {
                        currentPage ++;
                        currentRowsPerPage = 0;
                        this.pages[ currentPage ] = { activos: { values: [] }, pasivosCapital: { values: [ element ] } }
                    }
                }
                const lengthOfLastPage = this.pages[ this.pages.length - 1].activos.values.length + this.pages[ this.pages.length - 1].pasivosCapital.values.length;
                if( lengthOfLastPage > this.rowsNumberPerReport - 10 ) {
                    currentPage ++;
                    currentRowsPerPage = 0;
                    this.pages[ currentPage ] = { activos: { values: [] }, pasivosCapital: { values: [] } }
                }
            }
            catch(err) {
                alert(err)
            }
        },

        async Print(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            })
            
            
            //doc.deletePage(2)

            doc.autoPrint({variant: 'non-conform'});
            const blob = doc.output("bloburl")
            window.open(blob)

            this.loading = false
        },

        async Download(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            });
            
            //doc.deletePage(2)
            doc.save(`Anexo de Balance de Comprobación - ${ this.businessName } - ${ this.dataProps.balanceDate }.pdf`); //Agregado por Juan 13082023

            this.loading = false
        },
        async sendMail() {
            try {
                this.mailLabel = null;
                var doc = new jsPDF('p', 'pt', 'letter')
                //console.log(doc);
                //console.log(this.$refs.dataprint);
                await doc.html(this.$refs.dataprint, {
                    callback: function(doc){
                        doc.output()
                    },
                    x: 0,
                    y: 0,
                    margin: [0, 0, 0, 0],
                    html2canvas:{ 
                        scale: 0.612,
                        //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                        useCORS: true,
                        logging: false,
                        allowTaint: true
                    },
                });
                
                const body = {
                    dtefilebuffer: new Buffer.from(doc.output('arraybuffer')),
                    dtejson: this.dataProps.data.dteJson,
                    dtefilebufferfilename: this.dataProps.data.header.ID_Invoice+ "_"+this.dataProps.data.header.InvoiceDate.replaceAll("/","-") + "_"+this.dataProps.data.header.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".pdf"
                };

                // console.log(body);
                this.$API.invoices.sendInvoiceMail(body);

                this.mailLabel = 'Se envió el correo correctamente';
                this.$emit('mailSent');
            } catch (error) {
                this.mailLabel = 'Ocurrió un error al enviar el correo';
                console.log(error);
            }
        },
        async downLoadJSON() {
            try {
                const aElement = document.createElement('a');
                aElement.setAttribute('download', this.dataProps.data.header.ID_Invoice+ "_"+this.dataProps.data.header.InvoiceDate.replaceAll("/","-") + "_"+this.dataProps.data.header.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".pdf");
                const blob = new Blob([JSON.stringify(this.dataProps.data.dteJson)], { type: 'application/json' });
                const href = URL.createObjectURL(blob);
                aElement.href = href;
                aElement.download = this.dataProps.data.header.ID_Invoice+ "_"+this.dataProps.data.header.InvoiceDate.replaceAll("/","-") + "_"+this.dataProps.data.header.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".json";
                aElement.click();
                URL.revokeObjectURL(href);
            } catch (error) {
                this.mailLabel = 'Ocurrió un error al enviar el correo';
                console.log(error);
            }
        }
    }
}
</script>

<style scoped>

    .u-row .u-col-100{
        width: 100% !important;
    }
    .btnclean{
        border-radius: 15px;
        border: 1px solid white;
        background-color: #F29D35 !important;
        color: white !important;
    }

    * {
        font-family: Arial, Helvetica, sans-serif !important;
    }

</style>
